<template>
  <atoms-c-modal class="guide_alert" is-open @close="closeModal">
    <template #title>
      <h2>
        헬로메이플 PC 앱
        <em>설치 가이드</em>
      </h2>
    </template>
    <template #body>
      <atoms-c-image file-name="guide-download" :density="3" width="100%" />
    </template>
  </atoms-c-modal>
</template>
<script lang="ts" setup>
const emit = defineEmits(['close'])
const closeModal = () => {
  emit('close')
}
</script>
<style lang="scss">
.guide_alert {
  h2 > em {
    color: #6e7480;
  }
}
</style>
